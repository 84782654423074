import {UmEntity, UmQuery} from "../core";



/**
 * (Entity)
 * @author syj
 * @date 2021-09-17 16:25
 */
export class CompanyEntity extends UmEntity {
	//公司名称
	public name?: string;
	//公司地址
	public addr?: string;
	//公司邮箱
	public email?: string;
	//公司电话
	public tel?: string;
	//备注
	public remark?: string;
}


/**
 * (Query)
 * @author syj
 * @date 2021-09-17 16:25
 */
export class CompanyQuery extends UmQuery {

	//公司名称
	public name?: string;
}
