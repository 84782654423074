export default {
    name: "公司名称",
    addr: "公司地址",
    email: "公司邮箱",
    tel: "公司电话",
    remark: "备注",
    createTime: "创建时间",
    operTime: "最后操作时间",
    operOperatorName: "操作人姓名"
}
